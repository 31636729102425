
import Network from './network';
// 封装各种接口请求
// export const 接口名 = () => Network.get('/路由',参数对象);
export default {
    // 登录
    login:(data) => Network.post('api/user/login',data),
    // 登录
    mobileLogin:(data) => Network.post('api/user/mobileLogin',data),
    // 获取字段
    GetTableSet:(data) => Network.get('api/Sys_Table_Set/GetTableSet',data),
    // 拖动列宽
    SaveTableSetWidth:(data) => Network.post('api/Sys_Table_Set/SaveTableSetWidth',data),
    // 保存字段
    SaveTableSet:(data) => Network.post('api/Sys_Table_Set/SaveTableSet',data),

    // 计价单位查询
    GetUnitList:(data) => Network.get('api/Sys_Purveyor_Price/GetUnitList',data),

    // 获取省市区
    getDistrictById:(data) => Network.get('api/District/GetDistrict',data),
    // 上传供应商图片
    UploadPurveyor:(data) => Network.post('api/Sys_Purveyor/Upload',data),
    // 供应商管理
    getPurveyor:(data) => Network.post('api/Sys_Purveyor/getPageData',data),
    // 交期信息
    getPurveyorJQ:(data) => Network.post('api/Sys_Purchase_Delivery/GetPageData',data),
    // 下拉档案
    GetVueDictionary:(data) => Network.post('api/Sys_Dictionary/GetVueDictionary',data),
    // 附件管理
    getFileList:(data) => Network.post('api/Sys_Purveyor_File/getPageData',data),
    addFileList:(data) => Network.post('api/Sys_Purveyor_File/Add',data),
    PurveyorDownFile:(data) => Network.post('api/Sys_Purveyor_File/DownFile',data),
    Purveyor_File_Log:(data) => Network.post('api/Sys_Purveyor_File_Log/getPageData',data),
    UploadFile:(data) => Network.post('api/Sys_Purveyor_File/UploadFile',data),
    DelUploadFile:(data) => Network.post('api/Sys_Purveyor_File/Del',data),

    editPurveyor:(data) => Network.post('api/Sys_Purveyor/Update',data),
    AddPurveyor:(data) => Network.post('api/Sys_Purveyor/Add',data),
    delPurveyor:(data) => Network.post('api/Sys_Purveyor/del',data),

    // 供应商价目表
    getPurveyorInfo:(data) => Network.post('api/Sys_Purveyor_Info/getPageData',data),
    // 新增供应商价目表
    AddPurveyorInfo:(data) => Network.post('api/Sys_Purveyor_Info/Add',data),
    // 编辑供应商价目表
    updatePurveyorInfo:(data) => Network.post('api/Sys_Purveyor_Info/update',data),
    // 删除供应商价目表
    delPurveyorInfo:(data) => Network.post('api/Sys_Purveyor_Info/del',data),
    // 统一调价
    BatchUpdateDiscount:(data) => Network.post('api/Sys_Purveyor_Price/BatchUpdateDiscount',data),

    // 供应商价目表修改历史
    getPurveyorLogInfo:(data) => Network.post('api/Sys_Purveyor_Log_Info/GetPageData',data),
    // 供应商价目表修改历史详细
    getPurveyorPriceLog:(data) => Network.post('api/Sys_Purveyor_Price_Log/GetPageData',data),

    // 供应商价目表维护
    getPurveyorPrice:(data) => Network.post('api/Sys_Purveyor_Price/getPageData',data),
    // 供应商价目表维护 导入
    ImportPurveyorPrice:(data) => Network.post('api/Sys_Purveyor_Price/Import',data),
    ExportPurveyorPrice:(data) => Network.post('api/Sys_Purveyor_Price/Export',data),
    //根据商品编码获取商品信息
    GetPrdtListPurveyorPrice:(data) => Network.post('api/Sys_Purveyor_Price/GetPrdtList ',data),
    // 商品分类档案
    GetItemCategoryList:(data) => Network.get('api/Sys_Purveyor_Price/GetItemCategoryList',data),
    //保存价目表 
    SavePurveyorPrice:(data) => Network.post('api/Sys_Purveyor_Price/Save ',data),
    //新增 保存价目表 
    AddSave:(data) => Network.post('api/Sys_Purveyor_Price/AddSave ',data),
    //删除价目表 
    DelPurveyorPrice:(data) => Network.post('api/Sys_Purveyor_Price/Del ',data),
    //计价单位 
    getPacking:(data) => Network.post('api/Sys_Packing/getPageData ',data),
    //税率 
    getTaxRate:(data) => Network.post('api/Sys_Tax_Rate/getPageData ',data),



    // 供应商类别
    getPurveyorCategory:(data) => Network.post('api/Sys_Purveyor_Category/getPageData',data),
    // 新增供应商类别
    addPurveyorCategory:(data) => Network.post('api/Sys_Purveyor_Category/Add',data),
    // 删除供应商类别
    delPurveyorCategory:(data) => Network.post('api/Sys_Purveyor_Category/del',data),
    // 编辑供应商类别
    updatePurveyorCategory:(data) => Network.post('api/Sys_Purveyor_Category/update',data),

    // 暂停合作
    getSuspend:(data) => Network.post('api/Sys_Suspend/getPageData',data),
    // 新增暂停合作
    addSuspend:(data) => Network.post('api/Sys_Suspend/Add',data),
    // 删除暂停合作
    delSuspend:(data) => Network.post('api/Sys_Suspend/del',data),
    // 编辑暂停合作
    updateSuspend:(data) => Network.post('api/Sys_Suspend/update',data),

    // 检验级别
    getInspectionLevel:(data) => Network.post('api/Sys_Inspection_Level/getPageData',data),
    // 新增检验级别
    addInspectionLevel:(data) => Network.post('api/Sys_Inspection_Level/Add',data),
    // 删除检验级别
    delInspectionLevel:(data) => Network.post('api/Sys_Inspection_Level/del',data),
    updateInspectionLevel:(data) => Network.post('api/Sys_Inspection_Level/update',data),

    // 税率档案
    getTaxRate:(data) => Network.post('api/Sys_Tax_Rate/getPageData',data),
    // 新增税率档案
    addTaxRate:(data) => Network.post('api/Sys_Tax_Rate/Add',data),
    // 删除税率档案
    delTaxRate:(data) => Network.post('api/Sys_Tax_Rate/del',data),
    updateTaxRate:(data) => Network.post('api/Sys_Tax_Rate/update',data),

    // 签约方式
    getSigningMethod:(data) => Network.post('api/Sys_Signing_Method/getPageData',data),
    // 新增签约方式
    addSigningMethod:(data) => Network.post('api/Sys_Signing_Method/Add',data),
    // 删除签约方式
    delSigningMethod:(data) => Network.post('api/Sys_Signing_Method/del',data),
    updateSigningMethod:(data) => Network.post('api/Sys_Signing_Method/update',data),

    // 仓库列表
    GetStockList:(data) => Network.get('api/purchase/GetStockList',data),
    // 获取价目表信息 产品列表
    GetPrdtListpurchase:(data) => Network.post('api/purchase/GetPrdtList',data),
    // 新增购物车
    addCart:(data) => Network.post('api/purchase/AddCart',data),
    // 购物车数据查询
    getCartPrdtList:(data) => Network.get('api/purchase/GetCartPrdtList',data),
    // 修改购物车数量
    UpdateCartQty:(data) => Network.post('api/purchase/UpdateCartQty',data),
    // 修改购物车选中状态
    UpdateCartCheck:(data) => Network.post('api/purchase/UpdateCartCheck',data),
    // 删除购物车数据
    DeleteCart:(data) => Network.post('api/purchase/DeleteCart',data),
    // 获取供应商
    GetPurveyorList:(data) => Network.get('api/purchase/GetPurveyorList',data),
    // 获取其他供应商价目表
    GetPurveyorPrdtList:(data) => Network.get('api/purchase/GetPurveyorPrdtList',data),
    // 更换供应商
    UpdateCartPurveyor:(data) => Network.post('api/purchase/UpdateCartPurveyor',data),
    // 初始化供应商档案
    InitPurveyor:(data) => Network.get('api/Sys_Purveyor/InitPurveyor',data),
    //批量加入购物车
    BachAddCart:(data) => Network.post('api/purchase/BachAddCart',data),
    // 获取购物车产品个数
    getCartNum:(data) => Network.get('api/purchase/GetCartNum',data),

    // 商品分类
    GetCategory:(data) => Network.get('api/Purchase/Prdt/GetCategory',data),
    // 产品属性--日常补货页面
    GetPrdtBaseInfo:(data) => Network.get('api/Purchase/Prdt/GetPrdtBaseInfo',data),
    // 商品列表----日常补货页面
    GetPrdtList:(data) => Network.get('api/Purchase/Prdt/GetPrdtList',data),

    // 产品属性---缺货补货页面
    GetPrdtBaseInfoNotQty:(data) => Network.get('api/Purchase/Prdt/GetPrdtBaseInfoNotQty',data),
    // 商品列表-----缺货补货页面
    GetPrdtNotQtyList:(data) => Network.get('api/Purchase/Prdt/GetPrdtNotQtyList',data),

    // 单个加入采购任务
    AddTask:(data) => Network.post('api/Purchase/Prdt/AddTask',data),
    // 当前页加入采购任务
    BachAddTask:(data) => Network.post('api/Purchase/Prdt/BachAddTask',data),
    // 一键加入采购任务
    BachAddTaskCondition:(data) => Network.post('api/Purchase/Prdt/BachAddTaskCondition',data),

    // 采购任务----查询
    GetTaskPrdtList:(data) => Network.get('api/Purchase/Prdt/GetTaskPrdtList',data),
    // 采购任务---- 修改数量
    UpdateTaskQty:(data) => Network.post('api/Purchase/Prdt/UpdateTaskQty',data),
    // 采购任务---- 修改选中状态
    UpdateTaskCheck:(data) => Network.post('api/Purchase/Prdt/UpdateTaskCheck',data),
    // 采购任务---- 更换供应商
    UpdateTaskPurveyor:(data) => Network.post('api/Purchase/Prdt/UpdateTaskPurveyor',data),
    // 采购任务---- 删除
    DeleteTask:(data) => Network.post('api/Purchase/Prdt/DeleteTask',data),

    // 查询结算 
    GetSettlePrdtList:(data) => Network.get('api/purchase/GetSettlePrdtList',data),
    //地址识别
    GetAddressSplit:(data) => Network.get('api/purchase/GetAddressSplit',data),
    // 提交订单
    SubmitOrder:(data) => Network.post('api/Purchase/Order/SubmitOrder',data),
    //下载Excel
    ExportError:(data) => Network.post('api/Sys_Purveyor_Price/ExportError',data),

    //生成询价单=============
    // 生成图片并保存报价单
    SaveQuotation:(data) => Network.post('api/Quotation/SaveQuotation',data),
    // 导出文件，返回日期+文件名
    exportQuotation:(data) => Network.post('api/Quotation/ExportQuotation',data),
    // 下载图片
    downLoadImg:(data) => Network.post('api/Quotation/DownLoadImg',data),
    // 下载Excel
    downLoadExcel:(data) => Network.post('api/Quotation/DownLoadExcel',data),
    // 获取用户报价单
    getQuotationList:(data) => Network.get('api/Quotation/GetQuotationList',data),
    // 获取用户报价单详情
    getQuotationDetail:(data) => Network.get('api/Quotation/GetQuotationDetail',data),

    // 订单列表
    getOrderList:(data) => Network.get('api/Purchase/Order/GetBuyerOrders',data),
    // 订单明细
    getOrdersDetails:(data) => Network.get('api/Purchase/Order/GetBuyerOrderInfo',data),
    // 订单取消
    cancelOrder:(data) => Network.post('api/Purchase/Order/CancelOrder',data),
    // 确认订单
    sureOrder:(data) => Network.post('api/Purchase/Order/SureOrder',data),
    // 确认付款
    SureOrderPay:(data) => Network.post('api/Purchase/Order/SureOrderPay',data),
    // 确认通知
    SureOrderNotice:(data) => Network.post('api/Purchase/Order/SureOrderNotice',data),
    // 订单取消
    CancelOrder:(data) => Network.post('api/Purchase/Order/CancelOrder',data),
    // 退款取消
    ReturnPayCancelOrder:(data) => Network.post('api/Purchase/Order/ReturnPayCancelOrder',data),
    // 确认退款
    SureReturnPay:(data) => Network.post('api/Purchase/Order/SureReturnPay',data),
    // 修改仓库
    UpdateOrderStock:(data) => Network.post('api/Purchase/Order/UpdateOrderStock',data),
    // 修改外部单号
    UpdateOrderExternalNo:(data) => Network.post('api/Purchase/Order/UpdateOrderExternalNo',data),
    // 保存订单
    SaveOrder:(data) => Network.post('api/Purchase/Order/SaveOrder',data),
    // 再来一单
    BuyAgain:(data) => Network.get('api/Purchase/Order/BuyAgain',data),
    // 上传凭证
    UploadPurchase:(data) => Network.post('api/Sys_Purchase_Order/Upload',data),
    








    // 注册
    register:(data) => Network.post('api/user/Register',data),
    //  获取注册页面配置
    GetRegisterPageConfig:(data) => Network.get('api/configCg/GetRegisterPageConfig',data),
    //验证公司、手机号
    checkphonecompany:(data) => Network.get('api/user/CheckPhoneOrCompany',data),
    // 找回密码
    modify:(data) => Network.post('api/user/modifyPwd',data),
    // 获取图文验证码
    getVierificationCode:() => Network.get('api/user/GetVierificationCode'),
    // 发送短信
    sendMobileCode:(data) => Network.post('api/user/SendRegisterCode',data),
    // 忘记密码
    forgetPwd:(data) => Network.post('api/user/ForgetPwd',data),
    // 验证短信
    ForgetPwdValidCode:(data) => Network.post('api/user/ForgetPwdValidCode',data),
    // 修改密码
    updatePwd:(data) => Network.post('api/user/UpdatePwd',data),
    // 获取用户信息
    getCurrentUserInfo:(data) => Network.get('api/user/GetCurrentUserInfo',data),
    // 系统配置
    config:() => Network.get('api/configCg/GetSysConfig'),
    // 获取注册协议
    getSysConfigToRegister:() => Network.get('api/user/GetSysConfigToRegister'),
    // 轮播图
    chartlist:(data) => Network.get('api/SysContentConfig/GetSysCarouselChartList',data),
    // 新闻公告
    newslist:(data) => Network.get('api/SysContentConfig/GetNewsList',data),
    // 底部导航信息
    navigationlist:(data) => Network.get('api/SysContentConfig/GetBottomNavigationsList',data),
    // 头部导航分类
    classify:() => Network.get('api/MallSlassification/GetMallSlassificationList'),
    // 分类下拉
    SearchMallSlassificationSelect:(data) => Network.get('api/MallSlassification/SearchMallSlassificationSelect',data),

    
    // 搜索 产品条件查询
    getStandardCondition:(data) => Network.get('api/Standard/GetPrdtBaseInfo',data),
    // 搜索 产品列表查询
    getStandardProduct:(data) => Network.get('api/Standard/GetPrdtList',data),
    // 保存样式
    SavePrdtStyle:(data) => Network.post('api/configCg/SavePrdtStyle',data),
    
    // 产品详情
    getPrdtInfo:(data) => Network.get('api/Prdt/GetPrdtInfo',data),
    // 热词搜索
    getSearchRecordList:(data) => Network.get('api/Prdt/GetSearchRecordList',data),
    // 加入购物车
    // addCart:(data) => Network.post('api/Cart/AddCart',data),
    // 修改购物车数量
    // updateCartQty:(data) => Network.post('api/Cart/UpdateCartQty',data),
    // 修改购物车选中项
    // updateCartCheck:(data) => Network.post('api/Cart/UpdateCartCheck',data),
    // 修改购物车选中项
    delCart:(data) => Network.post('api/Cart/DelCart',data),
    // 批量修改购物车选中项
    DelCartBatch:(data) => Network.post('api/Cart/DelCartBatch',data),
    // 获取购物车产品集合
    // getCartPrdtList:(data) => Network.get('api/Cart/GetCartPrdtList',data),
    // 获取结算页面产品集合
    // getSettlePrdtList:(data) => Network.get('api/Cart/GetSettlePrdtList',data),
    // 重新计算运费 
    CalcFreightAmt:(data) => Network.post('api/Cart/CalcFreightAmt',data),


    // 添加到货通知
    addCollection:(data) => Network.post('api/Collections/AddCollection',data),
    // 删除到货通知
    delCollection:(data) => Network.post('api/Collections/DelCollection',data),
    // 修改到货通知
    updateCollection:(data) => Network.post('api/Collections/UpdateCollection',data),
    // 添加到货通知
    getCollectionList:(data) => Network.get('api/Collections/GetPrdtList',data),
    // 获取服务器时间
    getCurrentDateTime:(data) => Network.get('api/purchase/GetCurrentDateTime',data),
    // 获取收货地址数据
    getUserAddressList:(data) => Network.get('api/BuyerAddress/GetUserAddress',data),
    // 获取收货地址数据 带分页
    getUserAddressListPage:(data) => Network.get('api/BuyerAddress/GetUserAddressPage',data),
    // 获取收货地址数据
    getUserAddressInfo:(data) => Network.get('api/BuyerAddress/GetUserAddressInfo',data),
    // 新增收货地址数据
    addBuyerAddress:(data) => Network.post('api/BuyerAddress/AddBuyerAddress',data),
    // 修改收货地址数据
    uptBuyerAddress:(data) => Network.post('api/BuyerAddress/UptBuyerAddress',data),
    // 删除收货地址数据
    delBuyerAddress:(data) => Network.post('api/BuyerAddress/DelBuyerAddress',data),
    // 设置默认收货地址
    setBuyerAddressDefault:(data) => Network.post('api/BuyerAddress/UptBuyerAddressDefault',data),
   
    // 退货列表
    getReturnOrderList:(data) => Network.get('api/Order/GetBuyerReturnOrders',data),
    // 退货详情
    getReturnOrderDetail:(data) => Network.get('api/Order/GetReturnOrderDetail',data),
    // 申请退货
    applyReturnOrder:(data) => Network.post('api/Order/ApplyReturnOrder',data),
    // 获取退货原因
    getReturnReasonList:(data) => Network.get('api/Order/GetReturnReasonList',data),
    // 获取站内消息
    getMessageList:(data) => Network.get('api/Message/GetMessageList',data),
    // 设置为已读
    setRead:(data) => Network.post('api/Message/UpdateIsRead',data),
    // 删除站内消息
    delMessage:(data) => Network.post('api/Message/DelMessage',data),
    // 对账列表
    getAccountList:(data) => Network.get('api/Account/GetAccountList',data),
    // 对账明细
    getAccountInfo:(data) => Network.get('api/Account/GetAccountInfo',data),
    // 获取开票信息
    getInvoInfo:() => Network.get('api/user/GetInvoInfo'),
    // 更新开票信息
    updateInvo:(data) => Network.post('api/user/UpdateInvo',data),
    // 更新收票信息
    updateReceipt:(data) => Network.post('api/user/UpdateReceipt',data),
    // 刷新taoken
    replaceToken:(data) => Network.post('api/user/replaceToken',data),
    //判断背景色是否变更
    changeColor:(data) => Network.get('api/configCg/IfColorChange',data),
    // 获取广告位
    GetAdvertisement:(data) => Network.get('api/SysContentConfig/GetAdvertisement',data),
    
    //获取支付方式
    GetPaymentList:(data) => Network.post('api/Pay/GetPayInfo',data),
    //提交支付
    PayOrder:(data) => Network.post('api/Pay/ToOrder',data),
    //获取支付状态
    GetOrderPayStatus:(data) => Network.get('api/Pay/GetOrderPayStatus',data),
    
    //多个订单支付
    ToOrderList:(data) => Network.post('api/Pay/ToOrderList',data),
    //订单通知接口
    NotifyOrder:(data) => Network.get('api/Pay/NotifyOrder',data),

    //对账单支付
    ToPyaAccountInfo:(data) => Network.post('api/Pay/ToPyaAccountInfo',data),
    //对账单支付状态查询
    NotifyAccountInfo:(data) => Network.get('api/Pay/NotifyAccountInfo',data),
    
    
    //非标定制品相关接口
    AddCustomized:(data) => Network.post('api/customized/AddCustomized',data),
    //上传文件
    UploadForApi:(data) => Network.post('api/customized/UploadForApi',data),
    //获取非标订制品
    GetCustomizedList:(data) => Network.get('api/customized/GetCustomizedList',data),
    //获取非标订制品 详情
    GetCustomizedDetail:(data) => Network.get('api/customized/GetCustomizedDetail',data),

    //保存产品自定义编码
    SavePrdtCustom:(data) => Network.post('api/Prdt/Custom/SavePrdtCustom',data),
    //自定义编码查询
    GetPrdtCustomList:(data) => Network.get('api/Prdt/Custom/GetPrdtCustomList',data),
    //删除产品自定义编码
    DelPrdtCustom:(data) => Network.post('api/Prdt/Custom/DelPrdtCustom',data),
    //下载导入Excel模板
    DownLoadPrdtCustom:(data) => Network.get('api/Prdt/Custom/DownLoadPrdtCustom',data),
    //导入表数据Excel文件夹
    ImportPrdtCustom:(data) => Network.post('api/Prdt/Custom/ImportPrdtCustom',data),
    //导出文件，返回日期+文件名
    ExportPrdtCustom:(data) => Network.post('api/Prdt/Custom/ExportPrdtCustom',data),

    //导出我的物料，用来添加购物车
    ExportMyPrdtCustom:(data) => Network.post('api/Prdt/Custom/ExportMyPrdtCustom',data),
    //导入表数据Excel文件夹
    ImportCartPrdt:(data) => Network.post('api/Prdt/Custom/ImportCartPrdt',data),
    //自定义编码购物车， 快速下单导入和查询后调用这个接口  返回所有自定义编码购物车的数据
    GetCartPrdtCustomList:(data) => Network.get('api/Prdt/Custom/GetCartPrdtCustomList',data),
    // 新增自定义编码到购物车，数量累加
    GetAddCartPrdtCustomList:(data) => Network.get('api/Prdt/Custom/GetAddCartPrdtCustomList',data),
    // 删除产品自定义编码
    DelCartPrdtCustom:(data) => Network.post('api/Prdt/Custom/DelCartPrdtCustom',data),
    // 编辑自定义编码购物车数量
    UpdateCartPrdtCustom:(data) => Network.post('api/Prdt/Custom/UpdateCartPrdtCustom',data),

    // 充值获取支付方式
    GetRechargePayInfo:(data) => Network.post('api/Pay/GetRechargePayInfo',data),
    // 充值
    ToRecharge:(data) => Network.post('api/Pay/ToRecharge',data),
    // 获取转入记录
    GetSurplusCheckPage:(data) => Network.get('api/user/GetSurplusCheckPage',data),
    // 充值-查询支付状态
    GetRechargePayStatus:(data) => Network.get('api/Pay/GetRechargePayStatus',data),
    // 设置支付密码
    SetPayPwd:(data) => Network.post('api/user/SetPayPwd',data),
    // 获取余额流水
    GetSurplusPage:(data) => Network.get('api/user/GetSurplusPage',data),
    // 校验支付密码是否正确
    CheckPayPwd:(data) => Network.post('api/user/CheckPayPwd',data),
    // 余额提现
    ToWithdrawal:(data) => Network.post('api/Pay/ToWithdrawal',data),
    
    // 校验支付密码是否正确
    CheckPayPwd:(data) => Network.post('api/user/CheckPayPwd',data),
    // 提现申请记录
    GetWithdrawalCheckPage:(data) => Network.get('api/user/GetWithdrawalCheckPage',data),

    // 货拉拉
    GetAccoutToken:(data) => Network.get('api/HuoLaLa/GetAccoutToken',data),
    GetUCityInfo:(data) => Network.get('api/HuoLaLa/GetUCityInfo',data),
    GetUOrderCalculate:(data) => Network.post('api/HuoLaLa/GetUOrderCalculate',data),
    GetUOrderRequest:(data) => Network.get('api/HuoLaLa/GetUOrderRequest',data),
    GetOrderCashier:(data) => Network.get('api/HuoLaLa/GetOrderCashier',data),
    GetOrderPayStatusForHll:(data) => Network.get('api/HuoLaLa/GetOrderPayStatus',data),

    // 埋点
    AddBuyerAct:(data) => Network.post('api/user/AddBuyerAct',data),
    // 自定义商品导出
    ExportPrdtList:(data) => Network.post('api/Prdt/ExportPrdtList',data),
    // 自定义商品导入
    ImportPrdtCustom:(data) => Network.post('api/Prdt/ImportPrdtCustom',data),

    // 投诉反馈=========
    //获取下拉框的值
    GetComplaintEnum:(data) => Network.get('api/Complaint/GetComplaintEnum',data),
    //分页获取数据
    GetComplaintPage:(data) => Network.get('api/Complaint/GetComplaintPage',data),
    //分页获取数据 详情
    GetComplaintInfo:(data) => Network.get('api/Complaint/GetComplaintInfo',data),
    //新增投诉反馈
    AddComplaint:(data) => Network.post('api/Complaint/AddComplaint',data),
    //删除投诉反馈
    DelComplaint:(data) => Network.post('api/Complaint/DelComplaint',data),


    //保存头像
    SetHeadImageUrl:(data) => Network.post('api/user/SetHeadImageUrl',data),

    //首页广告模块 SourceType（1电脑端,2微信端,3小程序,4安卓端,5苹果端,6代理端）
    GetAdverModule:(data) => Network.get('api/SysContentConfig/GetAdverModule',data),

    //获取首页配置
    GetHomeTemplate:(data) => Network.get('api/purchase/GetHomeTemplate',data),
}